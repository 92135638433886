import React from 'react'
import { Link } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import Banner from './banner'

import logo from '../images/circle-logo.svg'
import '../css/navbar.css'

/*
3/8 NO DeForest classes or Open Jump.
3/21 NO DeForest evening class or Open Jump.
3/22 NO DeForest classes or Open Jump.
DeForest CLOSED for Spring Break 3/24-3/30
Cottage Grove CLOSED for Spring Break 3/30-4/6."

Then... on:
March 9th: Take down the March 8th closure info
March 23: Take down March 21/22 closure info
March 30: Take down the DF Spring Break info
April 6: Take down banner
*/

const BANNER_MESSAGES = [
  {
    startDate: new Date('March 1, 2025'),
    endDate: new Date('March 23, 2025'),
    content: `
  <p style="text-wrap: balance;">
    <strong class='tracking-wide border-r pr-1'>3/21</strong> NO DeForest evening class or Open Jump<br/>
    <strong class='tracking-wide border-r pr-1'>3/22</strong> NO DeForest classes or Open Jump
  </p>
  `,
  },
  {
    startDate: new Date('March 1, 2025'),
    endDate: new Date('March 30, 2025'),
    content: `
  <p style="text-wrap: balance;">
	  <strong class='tracking-wide border-r pr-1'>3/24-3/30</strong> DeForest CLOSED for Spring Break
  </p>
  `,
  },
  {
    startDate: new Date('March 1, 2025'),
    endDate: new Date('April 6, 2025'),
    content: `
  <p style="text-wrap: balance;">
	  <strong class='tracking-wide border-r pr-1'>3/30-4/6</strong> Cottage Grove CLOSED for Spring Break
  </p>
  `,
  },
]

export default class NavBar extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      menuExpanded: false,
    }
  }

  toggleMenu = () => {
    this.setState(prevState => ({
      menuExpanded: !prevState.menuExpanded,
    }))
  }

  bannerMsg = () => {
    const now = new Date().getTime()
    const matchingMsgs = BANNER_MESSAGES.filter(msg => {
      return (
        (!msg.startDate || msg.startDate.getTime() <= now) &&
        msg.endDate.getTime() >= now
      )
    })

    return matchingMsgs.length > 0 ? (
      <div className="flex flex-wrap">
        <div
          className="mx-auto"
          dangerouslySetInnerHTML={{
            __html: matchingMsgs.map(msg => msg.content).join(''),
          }}
        ></div>
      </div>
    ) : (
      undefined
    )
  }

  render() {
    return (
      <>
        {this.bannerMsg() && <Banner>{this.bannerMsg()}</Banner>}
        <div className={`shadow w-full bg-white z-40 relative`}>
          <div className="container mx-auto relative">
            <nav className="lg:flex items-center justify-between bg-white py-10 lg:py-6">
              <div className="navbar-logo-container flex flex-no-shrink mr-16">
                <Link to="/">
                  <img className="navbar-logo" src={logo} alt="Logo" />
                </Link>
              </div>
              <div
                className={`${
                  this.state.menuExpanded ? '' : 'hidden'
                } font-semibold uppercase no-underline w-full ml-4 mt-32 lg:ml-0 lg:mt-0 lg:flex lg:justify-between`}
              >
                <Link
                  to="/classes"
                  className="flex mt-8 text-grey-darker hover:text-blue lg:underline-animated lg:inline-block lg:mt-0"
                  onClick={this.toggleMenu}
                >
                  Classes
                </Link>
                <Link
                  to="/open-jump"
                  className="flex mt-8 text-grey-darker hover:text-blue lg:underline-animated lg:inline-block lg:mt-0"
                  onClick={this.toggleMenu}
                >
                  Open Jump
                </Link>
                <Link
                  to="/parties"
                  className="flex mt-8 text-grey-darker hover:text-blue lg:underline-animated lg:inline-block lg:mt-0"
                  onClick={this.toggleMenu}
                >
                  Parties
                </Link>
                <Link
                  to="/state-meet"
                  className="flex mt-8 hover:text-purple lg:underline-animated lg:inline-block lg:mt-0 text-display uppercase text-xl text-purple-dark purple-underline tracking-wide"
                  onClick={this.toggleMenu}
                >
                  State Meet 2025
                </Link>
                <OutboundLink
                  href="https://app.jackrabbitclass.com/portal/ppLogin.asp?id=513632"
                  className="px-4 py-2 mt-8 lg:-mt-1 leading-none bg-blue rounded inline-block h-8 text-white hover:text-white hover:shadow-md shadow-transition uppercase"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={this.toggleMenu}
                >
                  Customer Portal
                </OutboundLink>
              </div>
              <div className="absolute mobile-menu lg:hidden py-05">
                <button
                  className="flex items-center mr-2 px-3 py-2 border rounded text-grey-darker border-grey hover:text-blue hover:border-blue-light"
                  onClick={this.toggleMenu}
                >
                  <svg
                    className="fill-current h-3 w-3"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <title>Menu</title>
                    <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                  </svg>
                </button>
              </div>
            </nav>
          </div>
        </div>
      </>
    )
  }
}
