import React from 'react'
import { Link } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import Email from './util/email'

import '../css/footer.css'
import Facebook from '../images/facebook.svg'
import DeforestContract from '../forms/Deforest-Party-Contract_2024-10.pdf'
import CottageGroveContract from '../forms/Cottage-Grove-Party-Contract_2025-03.pdf'

const Footer = () => (
  <footer className="py-16 bg-grey-darkest text-grey-light leading-normal text-sm">
    <p className="text-center text-xl pb-8">
      <span className="font-bold">Jump into fun at</span>
      <br /> Jump Around Gymnastics!
    </p>
    <div className="container mx-auto pl-0 w-full lg:pl-24 lg:w-full">
      <div className="flex flex-wrap">
        <div className="w-full lg:w-1/3 px-3 sm:pl-0 mb-12 lg:text-left">
          <h3>Locations</h3>
          <ul className="list-reset">
            <li className="break-words">
              <h4 className="pt-4 pb-2 prefix">DeForest</h4>
              <p className="flex items-center align-middle pb-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  className="w-4 h-4 mr-2"
                >
                  <path
                    fillRule="evenodd"
                    d="m3.855 7.286 1.067-.534a1 1 0 0 0 .542-1.046l-.44-2.858A1 1 0 0 0 4.036 2H3a1 1 0 0 0-1 1v2c0 .709.082 1.4.238 2.062a9.012 9.012 0 0 0 6.7 6.7A9.024 9.024 0 0 0 11 14h2a1 1 0 0 0 1-1v-1.036a1 1 0 0 0-.848-.988l-2.858-.44a1 1 0 0 0-1.046.542l-.534 1.067a7.52 7.52 0 0 1-4.86-4.859Z"
                    clipRule="evenodd"
                  />
                </svg>
                <OutboundLink
                  className="text-grey-light text-grey-light:hover no-underline flex items-center align-middle"
                  href="tel:1-608-846-5867"
                >
                  (608) 846&#45;5867
                </OutboundLink>
              </p>
              <p className="inline-flex items-center align-middle py-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  className="w-4 h-4 mr-2"
                >
                  <path
                    fillRule="evenodd"
                    d="M11.89 4.111a5.5 5.5 0 1 0 0 7.778.75.75 0 1 1 1.06 1.061A7 7 0 1 1 15 8a2.5 2.5 0 0 1-4.083 1.935A3.5 3.5 0 1 1 11.5 8a1 1 0 0 0 2 0 5.48 5.48 0 0 0-1.61-3.889ZM10 8a2 2 0 1 0-4 0 2 2 0 0 0 4 0Z"
                    clipRule="evenodd"
                  />
                </svg>

                <Email
                  classNames="text-grey-light text-grey-light:hover no-underline text-left"
                  addr="DF@JumpAroundGymnastics.com"
                />
              </p>
              <p className="text-grey-light no-underline flex items-start py-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  className="w-4 h-4 mr-2"
                >
                  <path
                    fillRule="evenodd"
                    d="m7.539 14.841.003.003.002.002a.755.755 0 0 0 .912 0l.002-.002.003-.003.012-.009a5.57 5.57 0 0 0 .19-.153 15.588 15.588 0 0 0 2.046-2.082c1.101-1.362 2.291-3.342 2.291-5.597A5 5 0 0 0 3 7c0 2.255 1.19 4.235 2.292 5.597a15.591 15.591 0 0 0 2.046 2.082 8.916 8.916 0 0 0 .189.153l.012.01ZM8 8.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"
                    clipRule="evenodd"
                  />
                </svg>

                <OutboundLink
                  href="https://maps.google.com/maps?ll=43.245984,-89.36741&z=15&t=m&hl=en-US&gl=US&mapclient=embed&cid=14851476681275146653"
                  className="text-grey-light no-underline"
                  rel="noreferrer noreferrer"
                  target="_blank"
                >
                  7129 River Rd.
                  <br />
                  DeForest, WI 53532
                </OutboundLink>
              </p>
            </li>
            <li className="break-words mt-2">
              <h4 className="py-2 prefix">Cottage Grove</h4>
              <p className="flex items-center align-middle pb-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  className="w-4 h-4 mr-2"
                >
                  <path
                    fillRule="evenodd"
                    d="m3.855 7.286 1.067-.534a1 1 0 0 0 .542-1.046l-.44-2.858A1 1 0 0 0 4.036 2H3a1 1 0 0 0-1 1v2c0 .709.082 1.4.238 2.062a9.012 9.012 0 0 0 6.7 6.7A9.024 9.024 0 0 0 11 14h2a1 1 0 0 0 1-1v-1.036a1 1 0 0 0-.848-.988l-2.858-.44a1 1 0 0 0-1.046.542l-.534 1.067a7.52 7.52 0 0 1-4.86-4.859Z"
                    clipRule="evenodd"
                  />
                </svg>
                <OutboundLink
                  className="text-grey-light text-grey-light:hover no-underline"
                  href="tel:1-608-622-5254"
                >
                  (608) 622&#45;5254
                </OutboundLink>
              </p>
              <p className="inline-flex items-center align-middle py-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  className="w-4 h-4 mr-2"
                >
                  <path
                    fillRule="evenodd"
                    d="M11.89 4.111a5.5 5.5 0 1 0 0 7.778.75.75 0 1 1 1.06 1.061A7 7 0 1 1 15 8a2.5 2.5 0 0 1-4.083 1.935A3.5 3.5 0 1 1 11.5 8a1 1 0 0 0 2 0 5.48 5.48 0 0 0-1.61-3.889ZM10 8a2 2 0 1 0-4 0 2 2 0 0 0 4 0Z"
                    clipRule="evenodd"
                  />
                </svg>
                <Email
                  classNames="text-grey-light text-grey-light:hover no-underline text-left"
                  addr="CG@JumpAroundGymnastics.com"
                />
              </p>
              <p className="text-grey-light no-underline flex items-start py-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  className="w-4 h-4 mr-2"
                >
                  <path
                    fillRule="evenodd"
                    d="m7.539 14.841.003.003.002.002a.755.755 0 0 0 .912 0l.002-.002.003-.003.012-.009a5.57 5.57 0 0 0 .19-.153 15.588 15.588 0 0 0 2.046-2.082c1.101-1.362 2.291-3.342 2.291-5.597A5 5 0 0 0 3 7c0 2.255 1.19 4.235 2.292 5.597a15.591 15.591 0 0 0 2.046 2.082 8.916 8.916 0 0 0 .189.153l.012.01ZM8 8.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"
                    clipRule="evenodd"
                  />
                </svg>
                <OutboundLink
                  href="https://www.google.com/maps/place/202+Limestone+Pass,+Cottage+Grove,+WI+53527/@43.1031998,-89.1974717,17z/data=!3m1!4b1!4m6!3m5!1s0x88065965eea13d57:0x324a09eb1662505f!8m2!3d43.1031959!4d-89.195283!16s%2Fg%2F11txj12n3m?hl=en-US"
                  className="text-grey-light no-underline"
                  rel="noreferrer noreferrer"
                  target="_blank"
                >
                  202 Limestone Pass
                  <br />
                  Cottage Grove, WI 53527
                </OutboundLink>
              </p>
            </li>
          </ul>
        </div>
        <div className="w-full lg:w-1/3 px-3 sm:pl-0 mb-8">
          <h3>Office Hours</h3>
          <h4 className="pt-4 pb-2 prefix">DeForest</h4>
          <ul className="list-reset">
            <li>
              <span className="font-bold">Mon</span> 2:00pm &ndash; 8:00pm
            </li>
            <li>
              <span className="font-bold">Tues</span> 9:30am &ndash;
              1:00pm/3:30pm &ndash; 8:00pm
            </li>
            <li>
              <span className="font-bold">Weds</span> 2:00pm &ndash; 8:00pm
            </li>
            <li>
              <span className="font-bold">Thurs</span> 9:30am &ndash;
              1:00pm/3:30pm &ndash; 8:00pm
            </li>
            <li>
              <span className="font-bold">Fri</span> 9:30am &ndash;
              12:00pm/4:00pm &ndash; 8:30pm
            </li>
            <li>
              <span className="font-bold">Sat</span> 9:00am &ndash; 1:00pm
            </li>
          </ul>
          <h4 className="pt-4 pb-2 prefix">Cottage Grove</h4>
          <ul className="list-reset">
            <li>
              <span className="font-bold">Mon</span> 3:30pm &ndash; 8:00pm
            </li>
            <li>
              <span className="font-bold">Tues</span> 9:00am &ndash;
              1:30pm/3:30pm &ndash; 8:00pm
            </li>
            <li>
              <span className="font-bold">Weds</span> 3:30pm &ndash; 8:00pm
            </li>
            <li>
              <span className="font-bold">Thurs</span> 9:00am &ndash;
              12:00pm/3:30pm &ndash; 8:00pm
            </li>
            <li>
              <span className="font-bold">Fri</span> 10:00am &ndash; 12:00pm
            </li>
            <li>
              <span className="font-bold">Sat</span> 9:00am &ndash; 12:00pm
            </li>
            <li className="mt-1">
              <span className="italic text-xs">(Subject to change)</span>
            </li>
          </ul>
        </div>
        <div className="w-full lg:w-1/3 px-3 sm:pl-0 mb-8">
          <h3>Navigation</h3>
          <ul className="list-reset navigation mt-3 text-grey-light">
            <li>
              <Link className="text-grey-light no-underline" to="/">
                Home
              </Link>
            </li>
            <li>
              <Link className="text-grey-light no-underline" to="/classes">
                Classes
              </Link>
            </li>
            <li>
              <Link className="text-grey-light no-underline" to="/open-jump">
                Open Jump
              </Link>
            </li>
            <li>
              <Link className="text-grey-light no-underline" to="/parties">
                Parties
              </Link>
            </li>
            <li>
              <Link className="text-grey-light no-underline" to="/state-meet">
                State Meet 2025
              </Link>
            </li>
            <li>
              <OutboundLink
                href="https://www.youtube.com/channel/UCKsStQpwbdTQSpWSIS6M28g/videos"
                className="text-grey-light no-underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                Instructional Videos
              </OutboundLink>
            </li>
            <li>
              <OutboundLink
                href="https://app.jackrabbitclass.com/portal/ppLogin.asp?id=513632"
                className="text-grey-light no-underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                Customer Portal
              </OutboundLink>
            </li>
            <li>
              <OutboundLink
                href="https://docs.google.com/forms/d/e/1FAIpQLSdiYSBWsZlbiaiKUftzYgfr-lxzPma_0LtCWAKGrZKad4PPEw/viewform?vc=0&c=0&w=1"
                className="text-grey-light no-underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                Waiver
              </OutboundLink>
            </li>
            <li>
              <OutboundLink
                href={DeforestContract}
                className="text-grey-light no-underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                DeForest Party Contract
              </OutboundLink>
            </li>
            <li>
              <OutboundLink
                href={CottageGroveContract}
                className="text-grey-light no-underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                Cottage Grove Party Contract
              </OutboundLink>
            </li>
            <li>
              <OutboundLink
                href="https://docs.google.com/forms/d/1MZEkNRgKrRr1s7nAp_IEaoqtYzeo-4rUgeNBPXcmuXk/edit"
                className="text-grey-light no-underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                Job Application
              </OutboundLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div className="mt-6 text-center">
      <OutboundLink
        className="align-top text-grey-light no-underline"
        href="https://www.facebook.com/JumpAroundGymnastics"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img className="facebook-icon" src={Facebook} alt="Facebook logo" />
        Find us on Facebook
      </OutboundLink>
    </div>
  </footer>
)

export default Footer
