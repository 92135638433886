import React from 'react'

const Banner = ({ children }) => {
  return (
    <section
      id="banner"
      className="z-50 w-full h-14 p-4 md:py-4 mb-1 bg-gradient-purple text-grey-lightest leading-normal"
    >
      <div className="container mx-auto">
        <div className="flex">
          <div className="w-full text-center">{children}</div>
        </div>
      </div>
    </section>
  )
}

export default Banner
